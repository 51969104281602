import Vue from "vue";
import Vuex from "vuex";
import { auth } from "./auth/auth.js"
import { rules } from "./validation/rules"
import { users } from "./users/users.js"
import axios from "../plugins/axios.config";
import router from "../router/index.js"

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userData: [],
    expandOnHover: false,
    drawer: true,
    sideBarImageUrl: "",
    windowSize: {
      x: 0,
      y: 0,
    },
    avatarUrl:
      "https://avatars.dicebear.com/api/avataaars/admin.svg?m=0&style=circle&top[]=shortHair&topChance=100&eyebrow[]=default&mouth[]=smile&skin[]=light",
  },
  getters: {
    App: (state) => {
      return state.drawer;
    },
    userDataGetter: (state) => {
      return { ...state }
    }
  },
  mutations: {
    onResize(state) {
      state.windowSize = { x: window.innerWidth, y: window.innerHeight };
    },
    setUser(state, payload) {
      state.userData = payload
    },

  },
  actions: {

  },
  modules: {
    auth, rules, users

  },
});
