import { setCookie, getCookie } from "@/assets/cookies.js";
import router from "@/router/index.js"
import axios from "@/plugins/axios.config";

const defaultErrors = {
    username: { status: false, messages: [] },
    password: { status: false, messages: [] },
};
const internetConnectionError = {
    username: ["خطا در اتصال به اینترنت، لطفا اتصال اینترنت خود را چک کرده و دوباره امتحان کنید.",],
    password: [],
};
export const networkErrorCondition = (err) => (err == "Error: Network Error" ||
    err == "Error: timeout of 10000ms exceeded")

export const auth = {
    namespaced: true,
    state: () => ({
        loginForm: { username: "", password: "" },
        loginFormErrors: defaultErrors,
        loginFormIsLoading: false,
        enterLoginPageLoading: false,
        enterLoginPageLoadingErrors: ""
    }),
    getters: {},
    mutations: {
        setLoginErrors(state, payload) {
            state.loginFormErrors = payload
        },
        setLoginFromLoading(state, payload) {
            state.loginFormIsLoading = payload
        },
        setEnterLoginPageLoading(state, payload) {
            state.enterLoginPageLoading = payload
        },
        loginLoadingErrors(state, payload) {
            state.enterLoginPageLoadingErrors = payload
        },
        loginSuccessHandler: (state, payloda) => {

        }
    },
    actions: {
        loginProcess: async ({ state, commit, dispatch }) => {
            commit("setLoginFromLoading", true)
            try {
                const response = await axios.post("/login", state.loginForm);
                dispatch("loginSuccessHandler", response.data)
                commit("setLoginFromLoading", false)
            } catch (error) {
                if (error) {
                    if (networkErrorCondition(error))
                        return dispatch("loginErrorHandler", {
                            data: { errors: internetConnectionError }
                        });
                    return dispatch("loginErrorHandler", error.response);
                }
            }
        },
        loginSuccessHandler: ({ commit }, payload) => {
            commit("setLoginErrors", defaultErrors)
            const userRole = payload.user.role;
            commit("setUser", payload.user, { root: true })
            setCookie("token", payload.plainTextToken, 7 * 24 * 60 * 60);
            if (userRole === "1")
                return router.push("admin");
            return router.push("user");

        },
        loginErrorHandler: ({ commit }, { data }) => {
            const errors = {
                username: { status: true, messages: data.errors.username || [] },
                password: { status: true, messages: data.errors.password || [] },
            }
            commit("setLoginErrors", errors);
            commit("setLoginFromLoading", false)
        },
        logoutProcess: async ({ dispatch }) => {
            try {
                const res = await axios.post("/logout");
                dispatch(logout)
            } catch (err) {
                dispatch("logout")
                return;
            }
            return;
        },
        enterLoginPageProcess: async ({ commit, rootState, rootGetters }) => {
            commit('setEnterLoginPageLoading', true)
            commit('loginLoadingErrors', "")
            const currentRoute = router.history.current.fullPath
            const root = rootGetters.userDataGetter
            const role = rootState.userData.role ? rootState.userData.role : ""
            // console.log(router.history.current.fullpath)
            // console.log(currentRoute.search("/admin"))
            if (getCookie("token")) {
                if (role == "1")
                    return currentRoute.search("/admin") == -1 ? router.push("/admin") : commit('setEnterLoginPageLoading', false);
                if (role == ("2" || "3"))
                    return currentRoute.search("/user") == -1 ? router.push("/user") : commit('setEnterLoginPageLoading', false);
                try {
                    const res = await axios.get("/user");
                    commit('setUser', res.data, { root: true })
                    if (res.data.role == "1") {
                        commit('setEnterLoginPageLoading', false);
                        if (currentRoute.search("/admin") === -1) {
                            // console.log("route isnt admin")
                            return router.push("/admin")
                        }
                        // return currentRoute.matched[0].path != "/admin" ? router.push("/admin") : commit('setEnterLoginPageLoading', false)
                    }
                    if (res.data.role == ("2" || "3")) {
                        commit('setEnterLoginPageLoading', false);
                        if (currentRoute.search("/user") === -1) {

                            return router.push("/user")
                        }
                        // return currentRoute.matched[0].path != "/user" ? router.push("/user") : commit('setEnterLoginPageLoading', false)
                    }
                } catch (error) {
                    if (
                        error == "Error: Network Error" ||
                        error == "Error: timeout of 10000ms exceeded"
                    ) {
                        commit("setEnterLoginPageLoading", false)
                        return commit('loginLoadingErrors', " خطایی رخ داد اینترنت خود را چک کرده و دوباره امتحان کنید")
                    }
                    // console.log(error)
                    setCookie("token", "", 1)
                    commit('setEnterLoginPageLoading', false)
                    return currentRoute != "/login" ? router.push("/login") : false;
                }
            }
            commit('setEnterLoginPageLoading', false)
            return currentRoute != "/login" ? router.push("/login") : false;

        },
        logout: ({ commit }) => {
            setCookie("token", "", 1);
            commit("setUser", [], { root: true })
            router.push("/login");
        }
    }
}