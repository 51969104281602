<template>
  <v-app>
    <TopNavbar />
    <ErrorLoading v-show="errors" />
    <NavigationDrawer />
    <CircularProgress v-if="loading" />
    <v-main :class="this.$vuetify.theme.dark ? null : 'blue-grey lighten-5'">
      <v-container fluid class="pa-6 pt-4">
        <v-fade-transition leave-absolute>
          <router-view></router-view>
        </v-fade-transition>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import LinearProgressBar from "@/components/loading/LinearProgressBar.vue";
import CircularProgress from "@/components/loading/CircularProgress.vue";
import ErrorLoading from "@/components/loading/ErrorLoading.vue";
import NavigationDrawer from "@/components/admin/NavigationDrawer.vue";
import TopNavbar from "@/components/admin/TopNavbar.vue";
import Home from "@/views/admin/Home";
import store from "@/store/index.js";

export default {
  name: "admin",
  components: {
    LinearProgressBar,
    ErrorLoading,
    CircularProgress,
    NavigationDrawer,
    TopNavbar,
    Home,
  },
  data: () => ({
    lazy: true,
  }),
  // beforeMount() {
  //   this.lazy = true;
  // },
  computed: {
    loading() {
      return store.state.auth.enterLoginPageLoading;
    },
    errors() {
      return store.state.auth.enterLoginPageLoadingErrors;
    },
  },
  mounted() {
    this.authProccess();
    console.log(store.state.auth);
  },
  methods: {
    authProccess() {
      store.dispatch("auth/enterLoginPageProcess");
    },
  },
  created() {
    this.$store.commit("onResize");
    this.$store.state.drawer =
      this.$store.state.windowSize.x <= 1264 ? false : true;

    const theme = {
      dark: true,
    };
    if (localStorage.theme) {
      const theme = JSON.parse(localStorage.theme);
      this.$vuetify.theme.dark = theme.dark;
    } else {
      localStorage.setItem(
        theme,
        JSON.stringify({ dark: this.$vuetify.theme.dark })
      );
    }
  },
};
</script>
<style>
* {
  font-family: "irs";
}
.theme {
  cursor: pointer;
}
</style>
