export const users = {
    namespaced: false,
    state: () => ({
        timestamp: "",
        usersTableLoading: false,
        headers: [
            { text: "نام و نام خانوادگی", value: "name", align: "center", sortable: false, },
            { text: "حق عضویت", value: "membershipFee", align: "center", sortable: false, },
            { text: "بدهی", value: "debt", align: "center", sortable: false, },
            { text: "عملیات", value: "id", align: 'center', sortable: false, },
        ],
        users: [/*{ id: 1, name: "محمد علی هادی خواه", memberShipFee: 9600000, debt: 3500000 },*/]
    })
}