export function setCookie(CookieName, CookieValue, ExpireSeconds = 60) {
    var date = new Date();
    date.setTime(date.getTime() + (ExpireSeconds * 1000));
    var expires = "expires=" + date.toUTCString();
    document.cookie = CookieName + "=" + CookieValue + ";" + expires + ";path=/";
}

export function getCookie(CookieName) {
    var name = CookieName + "=";
    var CookieArray = document.cookie.split(';');
    for (var i = 0; i < CookieArray.length; i++) {
        var CookieItem = CookieArray[i];
        while (CookieItem.charAt(0) == ' ') {
            CookieItem = CookieItem.substring(1);
        }
        if (CookieItem.indexOf(name) == 0) {
            return CookieItem.substring(name.length, CookieItem.length);
        }
    }
    return "";
}
