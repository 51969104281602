<template>
  <!-- <v-app>
    <v-main> -->

  <v-fade-transition>
    <router-view />
  </v-fade-transition>
  <!-- </v-main>
  </v-app> -->
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>
<style>
:root {
  --v-table-header-base: #d9eeff;
}
.blue__t-header.v-data-table table thead tr th {
  background-color: var(--v-table-header-base) !important;
}
.blue__t-header.v-data-table table thead tr th:first-child {
  border-top-right-radius: 8px;
}
.blue__t-header.v-data-table table thead tr th:last-child {
  border-top-left-radius: 8px;
}
.theme--dark.v-application .blue__t-header.v-data-table table thead tr th {
  background-color: black !important;
}
</style>
