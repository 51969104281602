import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/admin/Home.vue";
import Members from "@/views/admin/Members";
import Admin from "@/views/layouts/Admin";
import Login from "@/views/auth/Login";
import User from "../views/layouts/User.vue"
// import CircularProgress from "@/components/loading/CircularProgress.vue";
// import ErrorLoading from "@/components/loading/ErrorLoading.vue";
// import { getCookie } from "@/assets/cookies.js"
// import axios from "@/plugins/axios.config.js";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login,
    name: "Login",
    alias: "/login",
  },
  {
    path: "/admin",
    component: Admin,
    children: [
      {
        path: "/",
        name: "adminHome",
        component: Home,
      },
      {
        path: "members",
        name: "adminMember",
        component: Members,
      },
    ],
  },
  {
    path: "/user",
    name: "user",
    component: User

  },

];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
