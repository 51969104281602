<template>
  <v-progress-linear
    :active="true"
    color="deep-purple accent-4"
    indeterminate
    rounded
    height="4"
  >
  </v-progress-linear>
</template>

<script>
export default {
  name: "LinearProgressBar",
};
</script>

<style>
</style>