<template>
  <v-overlay>
    <v-alert transition="scale-transition" type="error">
      <v-row align="center">
        <v-col class="grow">
          {{ errors || "خطا" }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="next()">{{ btn_text ? btn_text : "تلاش مجدد" }}</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-overlay>
</template>

<script>
export default {
  name: "ErrorLoading",
  props: {
    btn_text: String,
    errors: String,
  },
  methods: {
    next: function () {
      this.$emit("next", true);
    },
  },
};
</script>

<style>
</style>