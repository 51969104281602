<template>
  <v-overlay v-if="loading || errors" :value="loading || errors">
    <v-progress-circular
      v-if="loading"
      indeterminate
      size="64"
    ></v-progress-circular>
    <v-alert
      transition="scale-transition"
      :value="errors ? true : false"
      type="error"
    >
      <v-row align="center">
        <v-col class="grow">
          {{ errors }}
        </v-col>
        <v-col class="shrink">
          <v-btn @click="authProccess">تلاش مجدد</v-btn>
        </v-col>
      </v-row>
    </v-alert>
  </v-overlay>
  <v-app v-else>
    <v-main>
      <div>hello this is user page my name is {{ "username" }}</div>
      <v-btn to="/login">login</v-btn>
      <v-btn @click="logout" :disabled="logoutloading" :loading="logoutloading"
        >logout</v-btn
      >
    </v-main>
  </v-app>
</template>

<script>
// import axios from "@/plugins/axios.config.js";
import store from "@/store/index.js";

export default {
  name: "User",
  data() {
    return {
      logoutloading: false,
    };
  },
  computed: {
    loading() {
      return store.state.auth.enterLoginPageLoading;
    },
    errors() {
      return store.state.auth.enterLoginPageLoadingErrors;
    },
  },
  mounted() {
    this.authProccess();
  },
  methods: {
    authProccess() {
      store.dispatch("auth/enterLoginPageProcess");
    },
    logout: async function () {
      this.logoutloading = true;
      await store.dispatch("auth/logoutProcess");
      this.logoutloading = false;
    },
  },
};
</script>

<style></style>
