<template>
  <tr v-if="columns">
    <v-skeleton-loader
      class="hidden-sm-and-up"
      type="list-item"
    ></v-skeleton-loader>
    <td class="hidden-xs-only" v-for="item in columns" :key="item">
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
    </td>
  </tr>

  <!-- <tr v-if="rows" class="">
    <td v-for="item in rows" :key="item">
      <v-skeleton-loader type="list-item"></v-skeleton-loader>
    </td>
  </tr> -->
</template>

<script>
export default {
  name: "TableLoading",
  props: {
    columns: { type: Number, default: 4 },
  },
};
</script>

<style>
</style>