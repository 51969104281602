import { gregorian_to_jalali } from "@/plugins/jalali.js";
export const weekDays = [
  "یکشنبه",
  "دوشنبه",
  "سه شنبه",
  "چهارشنبه",
  "پنجشنبه",
  "جمعه",
  "شنبه",
];
export function monthesName(month) {
  const names = [
    "فروردین",
    "اردیبهشت",
    "خرداد",
    "تیر",
    "مرداد",
    "شهریور",
    "مهر",
    "آبان",
    "آذر",
    "دی",
    "بهمن",
    "اسفند",
  ];
  return names[month];
}

export default () => {
  const now = new Date();
  let weekDay = now.getDay();
  let day = now.getDate();
  let month = now.getMonth() + 1;
  let year = now.getFullYear();
  const shamsi = gregorian_to_jalali(year, month, day);
  const weekDayName = weekDays[weekDay];
  const shamsiYear = shamsi[0];
  const shamsiMonthName = monthesName(shamsi[1] - 1);
  const shamsiDay = shamsi[2];

  return `${weekDayName} - 
    ${shamsiDay} 
    ${shamsiMonthName}
    ${shamsiYear} 
       `;
};
