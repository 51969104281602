<template>
  <!-- <v-scroll-x-transition> -->
  <div>
    <!-- <HelloWorld /> -->
    <v-btn to="/login"> salam</v-btn>
    <v-btn @click="logout" :disabled="loading" :loading="loading">
      logout</v-btn
    >
    {{ error }}
  </div>
  <!-- </v-scroll-x-transition> -->
</template>

<script>
import LinearProgressBar from "@/components/loading/LinearProgressBar.vue";
// import HelloWorld from "@/components/admin/HelloWorld.vue";

// import axios from "@/plugins/axios.config";
// import { setCookie } from "@/assets/cookies.js";
import store from "@/store/index.js";

const hello = {
  name: "Home",

  components: {
    // HelloWorld,
    LinearProgressBar,
  },
  data() {
    return {
      loading: false,
      error: "",
    };
  },
  methods: {
    logout: async function () {
      this.loading = true;
      await store.dispatch("auth/logoutProcess");
      this.loading = false;
    },
  },
};
export default hello;
</script>
