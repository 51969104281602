<template>
  <v-app-bar :color="$vuetify.theme.dark ? null : appBarColor" app>
    <v-app-bar-nav-icon @click="drawerHandler()"></v-app-bar-nav-icon>

    <span class="hidden-xs-only">{{ date }}</span>

    <v-spacer></v-spacer>
    <v-btn class="mx-2" icon @click="theme">
      <v-icon v-if="!$vuetify.theme.dark">{{ mdiBrightness2 }} </v-icon>
      <v-icon v-else>{{ mdiWhiteBalanceSunny }}</v-icon>
    </v-btn>
    <v-btn class="mx-2" to="/about/add" icon>
      <v-icon>{{ mdiTune }}</v-icon>
    </v-btn>
    <v-btn class="ml-1 mr-2" x-large icon>
      <v-skeleton-loader class="mx-auto" type="avatar"> </v-skeleton-loader>
      <img :src="$store.state.avatarUrl" alt="" />
    </v-btn>
  </v-app-bar>
</template>

<script>
import Today from "@/plugins/Today.js";
import { mdiWhiteBalanceSunny } from "@mdi/js";
import { mdiTune } from "@mdi/js";
import { mdiBrightness2 } from "@mdi/js";
export default {
  name: "TopNavbar",
  data() {
    return {
      appBarColor: "blue-grey lighten-5",
      mdiTune,
      mdiWhiteBalanceSunny,
      mdiBrightness2,
    };
  },
  methods: {
    theme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      if (localStorage.theme) {
        const theme = JSON.parse(localStorage.theme);
        theme.dark = !theme.dark;
        localStorage.setItem("theme", JSON.stringify(theme));
      } else {
        localStorage.setItem(
          "theme",
          JSON.stringify({ dark: this.$vuetify.theme.dark })
        );
      }
    },
    drawerHandler() {
      this.$store.commit("onResize");
      if (this.$store.state.windowSize.x <= 1264) {
        this.$store.state.expandOnHover = false;
        this.$store.state.drawer = !this.$store.state.drawer;
      }
      if (this.$store.state.windowSize.x > 1264) {
        this.$store.state.expandOnHover = !this.$store.state.expandOnHover;
      }
    },
  },
  computed: {
    date() {
      return Today();
    },
  },
};
</script>

<style></style>
