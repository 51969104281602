<template>
  <v-app class="full-height-bg-image">
    <v-main>
      <v-img
        src="/image/lock.jpg"
        class="fill-height full-vh"
        gradient="to top right, rgba(100,115,201,.13), rgba(25,32,72,.27)"
      >
        <v-container class="fill-height">
          <v-row align="center" justify="center">
            <CircularProgress v-if="loading" />

            <ErrorLoading
              v-if="errors"
              btn_text="تلاش مجدد"
              :errors="errors"
              @next="loginProcess"
            />

            <LoginCard v-if="!loading && !errors" />
          </v-row>
        </v-container>
      </v-img>
    </v-main>
  </v-app>
</template>

<script>
// import LoginCard from "@/components/login/LoginCard.vue";
import CircularProgress from "@/components/loading/CircularProgress.vue";
import ErrorLoading from "@/components/loading/ErrorLoading.vue";
import store from "@/store/index.js";
const LoginCard = () => ({
  component: import("@/components/login/LoginCard.vue"),
  loading: CircularProgress,
  error: ErrorLoading,
});
export default {
  components: {
    LoginCard,
    CircularProgress,
    ErrorLoading,
  },
  name: "Login",
  data() {
    return {
      hello: "hello",
    };
  },
  mounted() {
    this.$vuetify.theme.dark = false;
    this.loginProcess();
  },
  computed: {
    loading() {
      // return false;
      return store.state.auth.enterLoginPageLoading;
    },
    errors() {
      // return false;
      return store.state.auth.enterLoginPageLoadingErrors;
    },
  },
  methods: {
    loginProcess() {
      store.dispatch("auth/enterLoginPageProcess");
    },
  },
};
</script>

<style  >
@media (min-height: 450px) {
  .full-vh {
    height: 100vh;
  }
}
</style>
