export const rules = {
    state: {
        // required rule
        required: (value, fieldName) => (value == "" || value == null ? !!value || ` فیلد ${fieldName} الزامی است.` : !!value.trim() || `فیلد ${fieldName} الزامی است.`),
        // moreThan rule
        moreThan: (value, number, fieldName) => (value != "" && value.trim().length > number) || `${fieldName} باید بیشتر از ${number} کاراکتر داشته باشد`,
        // lessThan Rule
        lessThan: (value, number, fieldName) => (value != "" && value.trim().length < number) || `${fieldName} باید کمتر از ${number} کاراکتر داشته باشد`,
        // input must be english characters
        englishChar: (value) => new RegExp(/^[a-zA-Z0-9_+-.,!@#$%^&*();/|<>”‘:/=,\\s\d{}"~?]*$/i).test(value) || "باید کاراکتر های انگلیسی وارد کنید",

    }
}