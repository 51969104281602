import axios from 'axios'
import { getCookie } from "../assets/cookies"
const defualOptions = {
    // baseURL: "http://sandoghmu.test/api",
    baseURL: "https://vue.sandoghmu.ir/api",
    timeout: 10000,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-TOKEN': window.csrfToken,
        'X-Requested-With': 'XMLHttpRequest'
    }
}
let axiosInstance = axios.create(defualOptions);


axiosInstance.interceptors.request.use(function (config) {
    const token = getCookie('token');
    config.headers.Authorization = token ? `Bearer ${token}` : '';
    return config;
});

export default axiosInstance;