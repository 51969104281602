<template>
  <!-- <v-scroll-x-transition> -->
  <div>
    <!-- <v-card-title class="blue lighten-4"> -->
    <v-row class="ma-5">
      <v-col><h4>اعضای صندوق</h4> </v-col>
      <v-col class="d-flex justify-center">
        <v-btn v-if="users.length < 1 && !loading" small @click="fetchUsers">
          تلاش مجدد
          <v-icon>refresh</v-icon>
        </v-btn>
        <v-text-field
          v-if="users.length > 0"
          class="d-sm-flex d-none"
          dense
          v-model="search"
          append-icon="mdi-magnify"
          label="جستجوی اعضا"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col class="d-flex justify-end">
        <v-btn class="green white--text">
          <v-icon class="mx-2">person_add</v-icon>
          عضو جدید
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ma-5">
      <v-text-field
        class="d-sm-none d-flex"
        dense
        v-model="search"
        append-icon="mdi-magnify"
        label="جستجوی اعضا"
        single-line
        hide-details
      ></v-text-field>
    </v-row>

    <!-- </v-card-title> -->
    <v-card class="rounded-lg">
      <v-data-table
        :loading="loading"
        class="blue__t-header rounded-lg"
        :loding="headers.length > 0 ? false : true"
        :headers="headers"
        :items="users"
        :search="search"
      >
        <template v-if="loading" v-slot:body="">
          <TableLoading v-for="item in 6" :key="item" :columns="4" />
        </template>
        <template v-slot:item.debt="{ item }">
          <span
            :class="
              item.debt
                ? ' red--text text--darken-2'
                : ' green--text text--darken-2'
            "
          >
            {{ item.debt.toLocaleString("fa-IR") }}
            <small>ریال</small>
          </span>
        </template>
        <template v-slot:item.membershipFee="{ item }">
          <span class="blue--text text--darken-5">
            {{ item.membershipFee.toLocaleString("fa-IR") }}
            <small>ریال</small>
          </span>
        </template>
        <template v-slot:header="{ item }">
          <span>{{ item }}</span>
        </template>
        <template v-slot:item.name="{ item }" class="red">
          <v-chip class="blue white--text">
            {{ item.name }}
          </v-chip>
        </template>
        <template v-slot:item.id="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <!-- <v-btn color="primary" dark > Top </v-btn> -->
              <v-btn
                class="mx-5"
                elevation="1"
                color="primary"
                small
                icon
                v-bind="attrs"
                v-on="on"
                ><v-icon>info</v-icon></v-btn
              >
            </template>
            <span>مشاهده وضعیت</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </div>

  <!-- </v-scroll-x-transition> -->
</template>

<script>
import Store from "@/store";
import axios from "../../plugins/axios.config";
import TableLoading from "@/components/loading/TableLoading";
export default {
  components: {
    TableLoading,
  },
  name: "Members",
  data() {
    return {
      search: "",
    };
  },
  computed: {
    headers() {
      return Store.state.users.headers;
    },
    users() {
      return Store.state.users.users;
    },
    loading() {
      return Store.state.users.usersTableLoading;
    },
  },
  mounted() {
    console.log(Store.state.users.timestamp);
    // if (Store.state.users.users.length < 1) {
    if (Store.state.users.timestamp <= new Date().getTime()) this.fetchUsers();
    // }
  },
  methods: {
    fetchUsers: async () => {
      Store.state.users.timestamp = new Date().getTime() + 60 * 1000;
      Store.state.users.usersTableLoading = true;
      try {
        const users = await axios.get("/admin/users");
        Store.state.users.users = users.data;
        // console.log(users.data);
        Store.state.users.usersTableLoading = false;
      } catch (error) {
        // console.log(error);
        Store.state.users.usersTableLoading = false;
      }
    },
  },
};
</script>

<style>
</style>
