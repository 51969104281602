<template>
  <v-overlay>
    <v-progress-circular indeterminate size="64"></v-progress-circular>
  </v-overlay>
</template>

<script>
export default {
  name: "CircularProgress",
  props: {
    loading: Boolean,
  },
};
</script>

<style>
</style>