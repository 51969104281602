<template  >
  <v-navigation-drawer
    width="220"
    :src="this.$store.state.sideBarImageUrl"
    :expand-on-hover="this.$store.state.expandOnHover"
    right
    v-model="drawerState"
    app
  >
    <v-list>
      <v-list-item class="d-flex flex-row justify-center mt-3">
        <img
          :class="expandOnHover ? 'mb-3 mt-3' : ''"
          src="/image/Logo3.png"
          alt="موعود"
          :height="expandOnHover ? '50px' : '80px'"
        />
      </v-list-item>
      <v-list-item class="hidden-sm-and-up">
        <span class="white--text">{{ date }}</span>
      </v-list-item>
    </v-list>

    <!--  -->
    <v-list flat class="white--text theme--dark" dense shaped>
      <v-list-item-group v-model="selectedItem">
        <!-- active-class="blue--text text--lighten-1 white" -->
        <v-list-item
          :to="item.target"
          :class="
            $route.fullPath == item.target
              ? 'theme--dark blue--text text--lighten-1 white'
              : 'theme--dark'
          "
          v-for="(item, i) in items"
          :key="i"
        >
          <v-list-item-icon class="ml-3">
            <v-icon medium v-if="$route.fullPath == item.target" color="blue"
              >{{ item.icon }}
            </v-icon>
            <v-icon medium v-else color="white">{{ item.icon }} </v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title> {{ item.text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import { mdiViewDashboardOutline } from "@mdi/js";
import { mdiAccountDetailsOutline } from "@mdi/js";
import { mdiWalletOutline } from "@mdi/js";
import { mdiAccountCashOutline } from "@mdi/js";
import { mdiCashMarker } from "@mdi/js";
import Today from "@/plugins/Today";
export default {
  name: "NavigationDrawer",
  props: {},
  data() {
    return {
      svgPath: mdiViewDashboardOutline,
      // url: this.$route.fullPath,
      selectedItem: 0,
      items: [
        {
          text: "داشبورد",
          icon: mdiViewDashboardOutline,
          target: "/admin",
        },
        {
          text: "لیست اعضا",
          icon: mdiAccountDetailsOutline,
          target: "/admin/members",
        },
        // {
        //   text: "حق عضویت ها",
        //   icon: mdiWalletOutline,
        //   target: "/admin/transactions",
        // },
        // {
        //   text: "تسهیلات",
        //   icon: mdiAccountCashOutline,
        //   target: "/admin/loans",
        // },
        // {
        //   text: "مخارج صندوق",
        //   icon: mdiCashMarker,
        //   target: "/admin/expenses",
        // },
      ],
    };
  },
  methods: {
    mouse() {
      console.log("hello");
    },
  },

  computed: {
    date: () => Today(),
    drawerState: {
      get() {
        return this.$store.state.drawer;
      },
      set(state) {
        if (state !== this.$store.state.drawer) {
          this.$store.state.drawer = !this.$store.state.drawer;
        }
      },
    },
    darkMode: function () {
      return this.$vuetify.theme.dark;
    },
    expandOnHover() {
      return this.$store.state.expandOnHover;
    },
    imageUrl() {
      return this.img;
    },
  },
  watch: {
    darkMode() {
      this.$store.state.sideBarImageUrl = this.$vuetify.theme.dark
        ? "/sidebar-dark.png"
        : "/sidebar-light.png";
    },
  },
  mounted() {
    this.$store.state.sideBarImageUrl = this.$vuetify.theme.dark
      ? "/sidebar-dark.png"
      : "/sidebar-light.png";
  },
};
</script>

<style>
</style>